<template>
  <div 
    ref="sentinel"
    class="mb-48 md:mb-64"
    :class="{
      'min-h-[280px] md:min-h-[400px]': (showQuickBuy || model.useBigLayout || model.useBigLayoutTransparent),
      'md:min-h-[300px]': !showQuickBuy,
      'min-h-[420px]': !model.useSlimLayout && showQuickBuy && showExcerpt,
      'md:layout-container': !articlePage
    }"
  >
    <div class="overflow-x-clip relative">
      <div
        class="md:px-0 sm:flex justify-between mb-16 md:min-h-32"
        :class="{
          'px-24': !articlePage
        }"
      >
        <GlobalsLinkHelper
          v-if="model.blockLink && model.header"
          :to="model.blockLink.href"
          :target="model.blockLink.target ? model.blockLink.target : '_self'"
          class="block mb-8 sm:mb-0"
        >
          <h2 class="text-lg sm:text-xl leading-base sm:leading-lg">
            {{ model.header }}
          </h2>
        </GlobalsLinkHelper>
        <h2 v-else-if="model.header" class="block mb-8 sm:mb-0 text-lg sm:text-xl leading-base sm:leading-lg">
          {{ model.header }}
        </h2>
        <GlobalsLinkHelper
          v-if="
            (model.blockLink && productsResult && productsResult.products.length < 7) ||
              (model.blockLink && (isTablet || isMobile))
          "
          :to="model.blockLink?.href"
          :target="model.blockLink.target ? model.blockLink.target : '_self'"
          class="arrow-link self-end text-xs sm:text-sm sm:ml-auto"
        >
          {{ model.blockLink?.text }}
        </GlobalsLinkHelper>
      </div>
      <div
        v-if="productsResult && isMobile && !model.useMobileSlider"
        class="layout-container grid"
        :class="{
          'grid-cols-1 gap-y-12': (model.useBigLayout || model.useBigLayoutTransparent) && !model.useSlimLayout,
          'grid-cols-2 gap-x-8 gap-y-32': !model.useBigLayout && !model.useSlimLayout && !model.useBigLayoutTransparent,
          'grid-cols-3 gap-x-8 gap-y-16': model.useSlimLayout,
        }"
      >
        <div
          v-for="(product, index) in productsResult.products"
          :key="index"
        >
          <ProductSpot
            v-if="product"
            :product="product"
            :show-excerpt="showExcerpt"
            :show-quickbuy="showQuickBuy"
            :small-layout="productsResult.products.length > 4"
            :slim-layout="model.useSlimLayout"
            :big="model.useBigLayout"
            :big-transparent="model.useBigLayoutTransparent"
          />
        </div>
      </div>
      <ClientOnly v-else-if="productsResult && productsResult.products.length > 0 && isVisible">
        <Swiper
          :modules="[Navigation, FreeMode]"
          :space-between="isMobile ? 12 : 24"
          :slides-per-view="swiperSlides"
          :navigation="true"
          :free-mode="true"
          :threshold="30"
          :loop="false"
          :long-swipes-ratio="0.3"
          :speed="200"
          class="product-listing-swiper !overflow-visible"
          :class="{
            'hide-swiper-nav': (productsResult.products.length < 7 && (!model.useBigLayout && !model.useBigLayoutTransparent)),
            'mobileOnly:!px-24': !articlePage
          }"
        >
          <swiper-slide
            v-for="(product, index) in productsResult.products"
            :key="index"
            class="!h-auto"
          >
            <ProductSpot
              v-if="product"
              :product="product"
              :show-excerpt="showExcerpt"
              :show-quickbuy="showQuickBuy"
              :slim-layout="model.useSlimLayout"
              :small-layout="productsResult.products.length > 4 || isTablet && !model.useBigLayoutTransparent && !model.useBigLayoutTransparent"
              :big="model.useBigLayout && !isMobile"
              :big-transparent="model.useBigLayoutTransparent"
              :spot-index="index"
              :load="index < 7 ? 'eager' : 'lazy'"
              :fetch="index < 3 ? 'high' : ''"
              :is-product-block="true"
            />
          </swiper-slide>
        </Swiper>
      </ClientOnly>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { ProductListingBlock } from '~/content-types';
import { useUiStore } from '~/store/ui';
import { storeToRefs } from 'pinia';
import { useGlobalContentStore } from '~/store/globalContent';
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Navigation, FreeMode } from 'swiper/modules';
import 'swiper/css';
import { type IProductsResult } from '~/api-types';
import { onMounted, ref } from 'vue';

const route = useRoute();
const { isMobile, isTablet } = storeToRefs(useUiStore());
const { apiGet } = useApiFetch();
const isVisible = ref(false);
const sentinel = ref<Element>();
const productsResult = ref<IProductsResult>();

const props = defineProps<{
  model: ProductListingBlock;
  articlePage?: boolean
}>();

const showQuickBuy = computed<boolean>(()=> {
  if (isMobile.value && props.model.useSlimLayout) {
    return false;
  }
  return props.model.showQuickbuy || false;
});
const showExcerpt = computed<boolean>(()=> {
  if (isMobile.value && props.model.useSlimLayout) {
    return false;
  }
  return props.model.showExcerpt || false;
});

const swiperSlides = computed<number>(()=> {

  if (isMobile.value) return 2.1;

  if (productsResult.value?.products && productsResult.value?.products.length > 5) {
    if (!props.model.useBigLayout) {
      if (isTablet.value) {
        return 4.2;
      } else {
        return 6;
      }
    } else {
      if (isTablet.value) {
        return 3.2;
      }
    }
  }

  return 4; 
});

const mapOrder = (array, order) => {
  array = order.map((p) => {
    return array.find((product) => product.variants.some((variant) => variant.partNo === p.partNo));
  }).filter((item) => {
    return typeof item !== 'undefined' && item !== null;
  });
  return array;
};

const swipe1 = ref();

const swiperWidth = ref();

onMounted(async()=> {
  await loadData();
  const observer = new IntersectionObserver((entries) => {
    let entry = entries[0];
    if (entry.isIntersecting) {
      isVisible.value = true;
      observer.unobserve(sentinel.value as Element);
    }
  });
  if (sentinel.value) {
    observer.observe(sentinel.value as Element);
  }
  if (swipe1.value && swipe1.value[0]) {
    swiperWidth.value = swipe1.value[0].offsetWidth;
  }
});

const loadData = async() => {
  const marketSettings = useGlobalContentStore().marketSettings;
  let requestUrl = `products?countryCode=${marketSettings.countryCode}&language=${marketSettings.contentLanguage}&url=${route.path}`;
  // Manually selected products
  if (props.model.products?.length) {
    props.model.products.forEach((item) => {
      requestUrl += `&PartNumbers=${item.partNo}`;
    });
  } else {
    if (props.model.filters) {
      Object.entries(props.model.filters).forEach((filter) => {
        const keyWord = filter[0].charAt(0).toUpperCase() + filter[0].slice(1);
        filter[1].split(',').forEach((item: string)=> {
          requestUrl += `&${keyWord}=${item}`;
        });
      });
    }
  }
  const res = await apiGet<IProductsResult>(requestUrl);

  if (res?.products?.length && props.model.products?.length) {
    res.products = mapOrder(res.products, props.model.products);
    res.totalItemCount = res.products.length;
  }

  productsResult.value = res;
};

</script>

<style>
.product-listing-swiper {
  .swiper-button-next,
  .swiper-button-prev {
    @apply right-0 top-0 left-auto border border-grey400 h-32 w-32 -translate-y-28;

    &:after {
      @apply h-18 w-18;
    }
  }

  .swiper-button-prev {
    @apply right-48;
  }

  .swiper-button-disabled {
    @apply opacity-50 md:flex;
  }

  &.hide-swiper-nav {
    .swiper-button-next,
    .swiper-button-prev {
      @apply hidden;
    }
  }
}
</style>
